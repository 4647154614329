<template>
  <section class="page_wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h4>Pagina non trovata</h4>
          <br>
          <h4>Ops! Qualcosa è andato storto</h4>
          <br>
          <router-link to="/" class="btn btn-brand">Torna alla home</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Notfound",
}
</script>

<style scoped>

</style>