<template>
  <template v-if="maintenance">
    <section class="maintenance">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb-5 mt-5">
            <h3 class="text-center" v-html="title"></h3>
            <div class="text-center paragraph" v-html="message"></div>
          </div>
        </div>
      </div>
    </section>
  </template>
  <template v-else>
    <Spinner/>
    <Header v-if="this.$store.state.menus.length > 0" :menus="headerMenu" :sidebarmenu="sidebarMenu"/>
    <main class="main-content">
      <router-view :key="$route.path"/>
      <PageView :page-data="pageData"/>
    </main>
    <Footer v-if="this.$store.state.menus.length > 0" :menus="footerMenu"/>
    <ScrollTop/>
  </template>
</template>


<script>
import axios from "axios";
import {BASE_BACKEND_URL, etoken} from "@/constants/constants";
import Spinner from "@/components/Spinner.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ScrollTop from "@/components/ScrollTop.vue";
import PageView from "@/views/PageView.vue";

export default {
  name: 'App',
  components: {
    PageView,
    Spinner,
    Header,
    Footer,
    ScrollTop
  },
  data() {
    return {
      maintenance : false,
      title: null,
      message: null,
      pageData: [],
    };
  },
  beforeMount() {
    this.$store.dispatch("setSpinnerLoading",true);
  },
  created() {
    this.getMaintenance();
  },
  methods: {
    async fetchAndCreateRoutes() {
      try {
        const response = await axios.get(
            BASE_BACKEND_URL + 'api/cms/v1/components',
            {
              headers: {
                'Content-Type': 'application/json',
                'e-token' : etoken
              }
            }
        );
        const data = response.data.components;
        // Aggiorna la variabile pageData con i dati ottenuti
        this.pageData = data;
        //console.log("this.pageDataaa",this.pageData);
        // Crea route per ogni pagina
        data.forEach(page => {
          //console.log("page components",page.components);
          const slug = page.slug;
          //imposto la route con il nome della pagina che visito, gli passo gli hooks associati, il path alla pagina che voglio caricare
          const route = {
            path:`${slug.startsWith("/") ? slug : "/" + slug}`,
            component: PageView,
            //oltre ai componenti della pagina passo anche il title della pagina cosi cambia in modo dinamico
            props: (route) => ({pageData: page.components, pageTitle: route.meta.title, pageDescription: route.meta.description}),
            name: slug,
            meta: {
              auth: false,
              title: page.title,
              description: page.description,
            },
          };
          this.$router.addRoute(route);
        });
        //console.log("Tutte le Routes", this.$router.getRoutes());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getMaintenance() {
      await axios.get("maintenance",{
        headers: {
          'Content-Type': 'application/json',
          'e-token': etoken
        }
      }).then((resp) => {
        this.title = resp.data.title;
        this.message = resp.data.message;
        this.maintenance = resp.data.active;
        this.fetchAndCreateRoutes();
        this.$store.dispatch("fetchMenus");
      }).catch((error) => {
        console.log(error);
      });
    },
  },
  computed: {
    // Filtra i menu
    headerMenu() {
      return this.$store.state.menus ? this.$store.state.menus.find(menu => menu.name === "Header") : null;
    },
    sidebarMenu() {
      return this.$store.state.menus ? this.$store.state.menus.find(menu => menu.name === "Sidebar") : null;
    },
    footerMenu() {
      return this.$store.state.menus ? this.$store.state.menus.find(menu => menu.name === "Footer") : null;
    }
  }
}
</script>

<style>
.main-content {
  padding-top: 75px; /*112*/
  overflow-x: hidden !important;
}
html, body {
  font-family: Montserrat, sans-serif;
  color: #757575;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/plugins/fonts/montserrat/montserrat.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

.scroll-top {
  width: 50px;
  height: 50px;
  background-color: #e93a3a;
  position: fixed;
  bottom: 5px;
  right: 20px;
  z-index: 9999;
  color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.scroll-top:hover {
  cursor: pointer;
}

.scroll-top svg {
  position: absolute;
  top: 15px;
  left: 17px;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/plugins/fonts/montserrat/montserrat.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/plugins/fonts/montserrat/montserrat.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/plugins/fonts/montserrat/montserrat.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/plugins/fonts/montserrat/montserrat.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  line-height: 1.5;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
}

p {
  font-family: "Montserrat", sans-serif;
  color: #757575;
  font-size: 15px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

ol, ul {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  list-style: none;
}

ol li, ul li {
  /*margin-left: 5px !important;*/
}

.header {
  z-index: 9999 !important;
}

iframe {
  border: 0;
}

a, a:focus, a:hover {
  text-decoration: none !important;
  outline: 0;
  color: #000;
}

blockquote {
  font-size: 18px;
  border-color: #000;
  padding: 20px 40px;
  text-align: left;
  color: #777;
}

.navbar-toggle .icon-bar {
  background: #000;
}

input[type=email], input[type=password], input[type=text], input[type=tel] {
  border-radius: 0;
  box-shadow: none;
  height: 45px;
  outline: none;
  font-weight: 200;
  font-size: 12px;
}

input[type=email]:focus, input[type=password]:focus, input[type=text]:focus, input[type=tel]:focus {
  box-shadow: none;
  border: 1px solid #000;
}

.form-control {
  box-shadow: none;
  border-radius: 0;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #000;
}

.btn-main, .btn-small, .btn-transparent, .btn-solid-border {
  background: #000;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  letter-spacing: 1px;
  padding: 14px 35px;
  text-transform: uppercase;
  font-weight: 200;
  border-radius: 0;
}

.btn-main.btn-icon i, .btn-icon.btn-small i, .btn-icon.btn-transparent i, .btn-icon.btn-solid-border i {
  font-size: 16px;
  vertical-align: middle;
  margin-right: 5px;
}

.btn-main:hover, .btn-small:hover, .btn-transparent:hover, .btn-solid-border:hover {
  background: black;
  color: #fff;
}

.btn-solid-border {
  border: 1px solid #000;
  background: #fff;
  color: #000;
}

.btn-transparent {
  background: transparent;
  padding: 0;
  color: #000;
}

.btn-transparent:hover {
  background: transparent;
  color: #000;
}

.btn-large {
  padding: 20px 45px;
}

.btn-large.btn-icon i {
  font-size: 16px;
  vertical-align: middle;
  margin-right: 5px;
}

.btn-small {
  padding: 8px 25px;
  font-size: 10px;
}

.btn-round {
  border-radius: 4px;
}

.btn-round-full {
  border-radius: 50px;
}

.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}

.mt-10 {
  margin-top: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.btn:focus {
  color: #ddd;
}

.w-100 {
  width: 100%;
}

.margin-0 {
  margin: 0 !important;
}

#preloader {
  background: #fff;
  height: 100%;
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 999999999;
}

.bg-shadow {
  background-color: #fff;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.08);
  padding: 20px;
}

.bg-gray {
  background: #f9f9f9;
}



.title {
  padding: 20px 0 30px;
}

.title h2 {
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.category-box {
  background-size: cover;
  margin-bottom: 30px;
  min-height: 350px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

@media (max-width: 400px) {
  .category-box {
    min-height: 250px;
  }
}

@media (max-width: 480px) {
  .category-box {
    min-height: 250px;
  }
}

.category-box.category-box-2 {
  min-height: 730px;
}

@media (max-width: 768px) {
  .category-box.category-box-2 {
    min-height: 400px;
  }
}

.category-box:hover img {
  transform: scale(1.1);
}

.category-box img {
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.category-box a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.category-box .content {
  position: absolute;
  z-index: 999;
  top: 0;
  padding: 25px;
}

@media (max-width: 768px) {
  .category-box .content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.category-box .content h3 {
  margin: 0;
  color: #333;
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .category-box .content h3 {
    font-size: 20px;
  }
}

.category-box .content p {
  margin: 6px 0 0;
}

.page-header {

  margin-top: 20px;
  border-bottom: none;
  padding: 30px 0;
}

.page-header h1 {
  font-weight: 200;
  margin: 0 0 6px 0;
}

.page-header .breadcrumb {
  background: transparent;
  padding: 1px;
  margin: 0;
}

.page-header .breadcrumb li {
  font-weight: 200;
  font-size: 12px;
}

.page-header .breadcrumb li a {
  color: #000;
}

.overly {
  position: relative;
}

.overly:before {
  content: "";
  background: rgba(0, 0, 0, 0.51);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overly-white {
  position: relative;
}

.overly-white:before {
  content: "";
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.social-media-icons ul li {
  display: inline-block;
}

.social-media-icons ul li a {
  font-size: 18px;
  color: #333;
  display: inline-block;
  padding: 7px 12px;
  color: #fff;
}

.social-media-icons ul li .twitter {
  background: #00aced;
}

.social-media-icons ul li .facebook {
  background: #3b5998;
  padding: 7px 18px;
}

.social-media-icons ul li .googleplus {
  background: #dd4b39;
}

.social-media-icons ul li .dribbble {
  background: #ea4c89;
}

.social-media-icons ul li .instagram {
  background: #bc2a8d;
}

@media (max-width: 480px) {
  .call-to-action .subscription-form {
    display: block;
  }
}

.call-to-action .subscription-form input {
  height: 50px;
}

@media (max-width: 480px) {
  .call-to-action .subscription-form input {
    text-align: center;
  }
}

.call-to-action .subscription-form .btn-main, .call-to-action .subscription-form .btn-solid-border, .call-to-action .subscription-form .btn-transparent, .call-to-action .subscription-form .btn-small {
  font-size: 14px;
}

@media (max-width: 480px) {
  .call-to-action .subscription-form .btn-main, .call-to-action .subscription-form .btn-solid-border, .call-to-action .subscription-form .btn-transparent, .call-to-action .subscription-form .btn-small {
    width: 100%;
  }
}

.dropdown-slide {
  position: static;
}

.dropdown-slide .open > a, .dropdown-slide .open > a:focus, .dropdown-slide .open > a:hover {
  background: transparent;
}

.dropdown-slide.full-width .dropdown-menu {
  left: 0 !important;
  right: 0 !important;
}

.dropdown-slide:hover .dropdown-menu {
  display: none;
  opacity: 1;
  display: block;
  transform: translate(0px, 0px);
  opacity: 1;
  visibility: visible;
  color: #777;
  transform: translateY(0px);
}

.dropdown-slide .dropdown-menu {
  border-radius: 0;
  opacity: 1;
  visibility: visible;
  position: absolute;
  padding: 15px;
  border: 1px solid #ebebeb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: 0.3s all;
  position: absolute;
  display: block;
  visibility: hidden;
  opacity: 0;
  transform: translateY(30px);
  transition: visibility 0.2s, opacity 0.2s, transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
}

@media (max-width: 480px) {
  .dropdown-slide .dropdown-menu {
    transform: none;
  }
}

.commonSelect {
  margin-left: 10px;
  padding-right: 6px;
  position: relative;
}

.commonSelect:before {
  content: "\f3d0";
  font-family: "themefisher-font";
  position: absolute;
  right: -4px;
  top: 4px;
  font-size: 10px;
}

.commonSelect select {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  border: none;
  padding: 0;
  height: auto;
  color: #555;
}

.commonSelect select:focus {
  box-shadow: none;
  border: none;
}

.tabCommon .nav-tabs {
  border-bottom: 0;
  margin-bottom: 10px;
}

.tabCommon .nav-tabs li {
  margin-right: 5px;
}

.tabCommon .nav-tabs li.active a {
  background-color: #000;
  border: 1px solid #000;
  color: #ffffff;
}

.tabCommon .nav-tabs a {
  border-radius: 0;
  background: #f9f9f9;
}

.tabCommon .nav-tabs a:hover {
  border: 1px solid transparent;
  background: #000;
  color: #fff;
}

.tabCommon .tab-content {
  padding: 20px;
  border: 1px solid #dedede;
}

.commonAccordion .panel, .commonAccordion-2 .panel {
  border-radius: 0;
  box-shadow: none;
}

.commonAccordion .panel .panel-heading, .commonAccordion-2 .panel .panel-heading {
  background: transparent;
  padding: 0;
}

.commonAccordion .panel .panel-title, .commonAccordion-2 .panel .panel-title {
  position: relative;
}

.commonAccordion .panel .panel-title a, .commonAccordion-2 .panel .panel-title a {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 10px;
}

.commonAccordion .panel .panel-title a:before, .commonAccordion-2 .panel .panel-title a:before {
  color: #555;
  content: "\f209";
  position: absolute;
  right: 25px;
  font-family: "themefisher-font";
}

.commonAccordion .panel .panel-title a.collapsed:before, .commonAccordion-2 .panel .panel-title a.collapsed:before {
  content: "\f217";
}

.list-circle {
  padding-left: 20px;
}

.list-circle li {
  list-style-type: circle;
}

.play-icon {
  border: 1px solid #dedede;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  font-size: 30px;
}

.play-icon i {
  line-height: 60px;
}

.alert-common {
  border-radius: 0;
  border-width: 2px;
}

.alert-common i {
  margin: 0 5px;
  font-size: 16px;
}

.alert-solid {
  background: transparent;
  color: #000;
}

@media (max-width: 480px) {
  .buttonPart li {
    margin-bottom: 8px;
  }
}

@media (max-width: 768px) {
  .buttonPart li {
    margin-bottom: 8px;
  }
}

.single-page-header {
  padding: 140px 0 70px;
  text-align: center;
  color: #fff;
  position: relative;
}

.top-header {
  border-bottom: 1px solid #dedede;
}

.top-header .container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.top-header .dropdown-menu {
  left: auto;
  right: 0;
  max-width: 300px;
}

@media (max-width: 480px) {
  .top-header .dropdown-menu {
    right: 0;
    left: 0;
    max-width: 100%;
  }
}

.top-header .contact-number {
  font-size: 12px;
  color: #333;
}

@media (max-width: 480px) {
  .top-header .contact-number {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .top-header .contact-number {
    text-align: center;
    padding: 0;
  }

  .top-header .container {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.top-header .contact-number i {
  margin-right: 4px;
  font-size: 18px;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .top-header .top-menu {
    text-align: center;
  }
}

.top-header .top-menu > li > a {
  color: #333;
  font-size: 15px;
  padding: 0 8px;
}

.top-header .top-menu > li > a:hover, .top-header .top-menu > li > a:focus {
  background: transparent;
}

.top-header .top-menu > li > a i {
  font-size: 16px;
  margin-right: 2px;
  vertical-align: middle;
}

@media (max-width: 480px) {
  .top-header .logo {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .top-header .logo {
    padding: 10px;
  }
}

.top-header .logo a {
  display: inline-block;
}

.cart-dropdown .media {
  position: relative;
  border-bottom: 1px solid #dedede;
  padding-bottom: 15px;
}

.cart-dropdown .media .pull-left {
  padding-right: 15px;
}

.cart-dropdown img {
  width: 60px;
}

.cart-dropdown h4 {
  color: #000;
  font-weight: 300;
  font-size: 14px;
}

.cart-dropdown .cart-price {
  color: #7f7f7f;
  font-size: 12px;
  font-weight: 200;
}

.cart-dropdown .remove {
  padding: 1px 6px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f7f8f9;
  color: #7f7f7f;
  font-size: 12px;
}

.cart-buttons {
  margin-top: 20px;
}

.cart-buttons li {
  display: inline-block;
  width: 49%;
}

.cart-buttons li a {
  display: block;
}

.cart-summary {
  margin-top: 10px;
  font-weight: 500;
  color: #000;
  font-size: 14px;
}

.cart-summary .total-price {
  float: right;
}

.navigation {
  margin-bottom: 0;
  padding: 10px 0;
}

.navigation .menu-title {
  display: none;
  font-size: 16px;
}

@media (max-width: 480px) {
  .navigation .menu-title {
    display: inline-block;
    padding-left: 10px;
  }
}

@media (max-width: 768px) {
  .navigation .menu-title {
    display: inline-block;
    padding-left: 10px;
  }
}

.navigation .navbar-nav > li {
  position: static;
}

.navigation .navbar-nav > li.active a {
  color: #000;
}

.navigation .navbar-nav > li > a {
  color: #333;
  font-size: 14px;
  padding: 20px 15px;
  text-transform: uppercase;
  transition: 0.2s ease-in-out 0s;
  border: 1px solid transparent;
}

.navigation .navbar-nav > li > a:hover, .navigation .navbar-nav > li > a:active, .navigation .navbar-nav > li > a:focus {
  background: none;
  color: #000;
}

.navigation .container {
  position: relative;
}

.navigation .nav .open > a {
  border: 1px solid transparent;
  background-color: transparent;
}

.navigation .navbar-nav {
  float: none;
  display: inline-block;
}

.navigation .dropdown-slide .dropdown-menu {
  right: auto;
  left: auto;
  border: none;
}

.navigation .dropdown-slide .dropdown-menu li a {
  color: #222;
  font-size: 12px;
  border: 1px solid transparent;
  display: block;
  padding: 8px 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  transition: 0.3s all;
}

.navigation .dropdown-slide .dropdown-menu li a:hover {
  background-color: #000;
  color: #fff;
}

.slider-item {
  text-align: center;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.slider-item::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.5;
  z-index: -1;
  top: 0;
  left: 0;
}

.slider-item .container {
  position: relative;
  display: table;
  max-width: 1170px;
  height: 100%;
}

.slider-item .slide-inner {
  transform: translate(0, -30%);
  top: 50%;
  left: 0;
  right: 0;
  position: absolute;
}

.slider-item h1 {
  color: #fff;
  font-weight: bold;
  font-size: 60px;
}

.slider-item p {
  color: #fff;
}

.slider-item .btn-main, .slider-item .btn-solid-border, .slider-item .btn-transparent, .slider-item .btn-small {
  margin-top: 25px;
}

.slider-item.white-bg .slide-inner h1 {
  color: #000;
}

.slider-item.white-bg .slide-inner p {
  color: #000;
}

.home-slider:hover .owl-nav {
  opacity: 1;
}

.home-slider .owl-nav {
  width: 100%;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  right: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.home-slider .owl-nav .owl-next, .home-slider .owl-nav .owl-prev {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: #fff;
  text-align: center;
}

.home-slider .owl-nav .owl-next {
  right: 0px;
  position: absolute;
}

.home-slider .owl-nav i {
  line-height: 60px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.54);
}

.hero-slider .slider-item .container {
  height: 600px;
}

.hero-slider .slider-item .container .row {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-slider .slider-item:focus {
  outline: 0;
}

.hero-slider .btn {
  font-size: 15px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  font-family: "Roboto Condensed";
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-width: 2px;
  outline: none;
  box-shadow: rgb(153, 153, 153) 0px 0px 0px 0px;
  box-sizing: border-box;
  padding: 12px 26px;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform-origin: 50% 50% 0px;
  border-radius: 0;
  transition: 0.3s;
}

.hero-slider .btn:hover {
  color: black;
  background-color: #fff;
}

.hero-slider h1 {
  white-space: normal;
  font-size: 49px;
  line-height: 49px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  letter-spacing: -2px;
  font-family: "Playfair Display";
  margin-bottom: 35px;
}

.heroSliderArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 0;
  background-color: #fff;
  font-size: 16px;
  transition: 0.3s;
  z-index: 999;
}

.heroSliderArrow:focus, .heroSliderArrow:hover {
  background-color: #000;
  color: #fff;
  outline: 0;
}

.heroSliderArrow.prevArrow {
  left: 20px;
}

.heroSliderArrow.nextArrow {
  right: 20px;
}

@media (max-width: 768px) {
  .heroSliderArrow {
    display: none !important;
  }
}

.product-item {
  margin-bottom: 30px;
}

.product-item .product-thumb {
  position: relative;
}

.product-item .product-thumb img {
  width: 100%;
  height: auto;
}

.product-item .product-thumb .bage {
  position: absolute;
  top: 12px;
  right: 12px;
  background: #000;
  color: #fff;
  font-size: 12px;
  padding: 4px 12px;
  font-weight: 300;
  display: inline-block;
}

/*
.product-item .product-thumb:before {
  transition: 0.3s all;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
 */
.product-item .product-thumb .preview-meta {
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  opacity: 0;
  transition: 0.2s;
  transform: translateY(10px);
}

.product-item .product-thumb .preview-meta li {
  display: inline-block;
}

.product-item .product-thumb .preview-meta li a, .product-item .product-thumb .preview-meta li span {
  background: #fff;
  padding: 10px 0px;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  transition: 0.2s all;
  width: 50px;
}

.product-item .product-thumb .preview-meta li a:hover, .product-item .product-thumb .preview-meta li span:hover {
  background: #000;
  color: #fff;
}

.product-item:hover .product-thumb:before {
  opacity: 1;
}

.product-item:hover .preview-meta {
  opacity: 1;
  transform: translateY(-20px);
}

.product-modal {
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  padding: 0 !important;
}

.product-modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.product-modal.fade .modal-dialog {
  transform: translate(0, 0);
}

.product-modal .close {
  width: 50px;
  float: none;
  position: absolute;
  right: 20px;
  z-index: 9;
  top: 20px;
  font-size: 30px;
  outline: none;
}

.product-modal .modal-dialog {
  width: 900px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

@media (max-width: 480px) {
  .product-modal .modal-dialog {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .product-modal .modal-dialog {
    width: 100%;
  }
}

.product-modal .modal-content {
  border-radius: 0;
  box-shadow: none;
  border: none;
}

.product-modal .modal-content .modal-body {
  padding: 30px;
}

.product-modal .modal-content .modal-body .modal-image img {
  width: 100%;
  height: auto;
}

.product-modal .modal-content .modal-body .product-short-details h2 {
  margin-top: 0;
  font-size: 22px;
  font-weight: 400;
}

.product-modal .modal-content .modal-body .product-short-details h2 a {
  color: #000;
}

@media (max-width: 480px) {
  .product-modal .modal-content .modal-body .product-short-details h2 {
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .product-modal .modal-content .modal-body .product-short-details h2 {
    margin-top: 15px;
  }
}

.product-modal .modal-content .modal-body .product-short-details .product-price {
  font-size: 30px;
  margin: 20px 0;
}

@media (max-width: 480px) {
  .product-modal .modal-content .modal-body .product-short-details .product-price {
    margin: 10px 0;
  }
}

.product-modal .modal-content .modal-body .product-short-details .btn-main, .product-modal .modal-content .modal-body .product-short-details .btn-solid-border, .product-modal .modal-content .modal-body .product-short-details .btn-transparent, .product-modal .modal-content .modal-body .product-short-details .btn-small {
  margin-top: 20px;
}

.product-modal .modal-content .modal-body .product-short-details .btn-transparent {
  color: #444;
  border-bottom: 1px solid #dedede;
}

.product-shorting {
  margin-bottom: 30px;
}

.product-shorting span {
  margin-right: 15px;
}

.product-category ul {
  padding-left: 15px;
}

.product-category ul li {
  margin-bottom: 4px;
}

.product-category ul li a {
  color: #666;
}

.product-category ul li a:hover {
  color: #000;
}

.single-product {
  padding: 60px 0 40px;
}

.single-product .breadcrumb {
  background: transparent;
}

.single-product .breadcrumb li {
  color: #000;
  font-weight: 200;
}

.single-product .breadcrumb li a {
  color: #000;
  font-weight: 200;
}

.single-product .product-pagination li {
  display: inline-block;
  margin: 0 8px;
}

.single-product .product-pagination li + li:before {
  padding: 0 8px 0 0;
  color: #ccc;
  content: "/ ";
}

.single-product .product-pagination li a {
  color: #000;
  font-weight: 200;
}

.single-product .product-pagination li a i {
  vertical-align: middle;
}

.single-product-slider .carousel .carousel-inner .carousel-caption {
  text-shadow: none;
  text-align: left;
  top: 20%;
  bottom: auto;
}

.single-product-slider .carousel .carousel-inner .carousel-caption h1 {
  font-size: 50px;
  font-weight: 100;
  color: #000;
}

.single-product-slider .carousel .carousel-inner .carousel-caption p {
  width: 50%;
  font-weight: 200;
}

.single-product-slider .carousel .carousel-inner .carousel-caption .btn-main, .single-product-slider .carousel .carousel-inner .carousel-caption .btn-solid-border, .single-product-slider .carousel .carousel-inner .carousel-caption .btn-transparent, .single-product-slider .carousel .carousel-inner .carousel-caption .btn-small {
  margin-top: 20px;
}

.single-product-slider .carousel .carousel-control {
  bottom: auto;
  background: #fff;
  width: 6%;
  padding: 10px 0;
}

.single-product-slider .carousel .carousel-control i {
  font-size: 40px;
  text-shadow: none;
  color: #555;
}

.single-product-slider .carousel .carousel-indicators li img {
  height: auto;
  width: 60px;
}

.single-product-slider .carousel .carousel-control.right, .single-product-slider .carousel .carousel-control.left {
  background-image: none;
  top: 40%;
}

.single-product-slider .carousel-indicators {
  margin: 10px 0 0;
  overflow: auto;
  position: static;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.single-product-slider .carousel-indicators li {
  background-color: transparent;
  border-radius: 0;
  display: inline-block;
  height: auto;
  margin: 0 !important;
  width: auto;
}

.single-product-slider .carousel-indicators li.active img {
  opacity: 1;
}

.single-product-slider .carousel-indicators li:hover img {
  opacity: 0.75;
}

.single-product-slider .carousel-indicators li img {
  display: block;
  opacity: 0.5;
}

.single-product-details .color-swatches {
  display: flex;
  align-items: center;
}

.single-product-details .color-swatches span {
  width: 100px;
  color: #000;
  font-size: 13px;
  font-weight: 600;
}

.single-product-details .color-swatches a {
  display: inline-block;
  width: 36px;
  height: 36px;
  margin-right: 5px;
}

.single-product-details .color-swatches li {
  display: inline-block;
}

.single-product-details .color-swatches .swatch-violet {
  background-color: #8da1cd;
}

.single-product-details .color-swatches .swatch-black {
  background-color: #000;
}

.single-product-details .color-swatches .swatch-cream {
  background-color: #e6e2d6;
}

.single-product-details .product-size {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.single-product-details .product-size span {
  width: 100px;
  color: #000;
  font-size: 13px;
  font-weight: 600;
  display: inline-block;
}

.single-product-details .product-size .form-control {
  display: inline-block;
  width: 130px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000;
  font-size: 12px;
  border: 1px solid #e5e5e5;
  border-radius: 0px;
  box-shadow: none;
}

.single-product-details .product-category {
  margin-top: 20px;
}

.single-product-details .product-category > span {
  width: 100px;
  color: #000;
  font-size: 13px;
  font-weight: 600;
  display: inline-block;
}

.single-product-details .product-category ul {
  width: 140px;
  display: inline-block;
}

.single-product-details .product-category ul li {
  display: inline-block;
  margin: 5px;
}

.single-product-details .product-quantity {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.single-product-details .product-quantity > span {
  width: 100px;
  color: #000;
  font-size: 13px;
  font-weight: 600;
  display: inline-block;
}

.single-product-details .product-quantity .product-quantity-slider {
  width: 60px;
  display: inline-block;
}

.single-product-details .product-quantity .product-quantity-slider .input-group-btn:first-child > .btn, .single-product-details .product-quantity .product-quantity-slider .p-quantity .input-group-btn:first-child > .btn-group {
  margin-right: -2px;
}

.single-product-details .product-quantity .product-quantity-slider button {
  border-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 8px 10px;
  margin-left: -1px;
  position: relative;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
  border-top-right-radius: 4px;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  margin-top: -2px;
  border-radius: 0;
  border-bottom-right-radius: 4px;
}

.bootstrap-touchspin .input-group-btn-vertical i {
  position: absolute;
  top: 3px;
  left: 5px;
  font-size: 9px;
  font-weight: normal;
}

/*=================================================================
  Pricing section
==================================================================*/
.pricing-table .pricing-item {
  padding: 40px 55px 65px;
  background: #fff;
  margin-bottom: 20px;
}

.pricing-table .pricing-item a.btn-main, .pricing-table .pricing-item a.btn-solid-border, .pricing-table .pricing-item a.btn-transparent, .pricing-table .pricing-item a.btn-small {
  text-transform: uppercase;
  margin-top: 20px;
}

.pricing-table .pricing-item li {
  font-weight: 400;
  padding: 10px 0;
  color: #666;
}

.pricing-table .pricing-item li i {
  margin-right: 6px;
}

.pricing-table .price-title {
  padding: 30px 0 20px;
}

.pricing-table .price-title > h3 {
  font-weight: 700;
  margin: 0 0 5px;
  font-size: 15px;
  text-transform: uppercase;
}

.pricing-table .price-title > p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 5px;
}

.pricing-table .price-title .value {
  color: #000;
  font-size: 50px;
  padding: 10px 0;
}

.instagram-feed {
  text-align: center;
}

.instagram-feed a {
  margin: 6px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.instagram-feed a:hover img {
  filter: grayscale(10);
}

.instagram-feed a img {
  width: 100%;
  max-height: 180px;
  -o-object-fit: cover;
  object-fit: cover;
}

.dashboard-menu .active {
  background: #000;
  color: #fff;
  border: 1px solid #000;
}

.dashboard-menu li {
  padding: 0;
  margin: 0 3px;
}

.dashboard-menu li a {
  padding: 10px 20px;
  border: 1px solid #dedede;
  display: inline-block;
}

@media (max-width: 768px) {
  .dashboard-menu li a {
    padding: 10px 15px;
  }
}

@media (max-width: 480px) {
  .dashboard-menu li a {
    padding: 10px 5px;
  }
}

@media (max-width: 400px) {
  .dashboard-menu li a {
    padding: 10px 8px;
    font-size: 12px;
    margin-bottom: 6px;
  }
}

.dashboard-wrapper {
  border: 1px solid #dedede;
  margin-top: 30px;
  padding: 20px;
}

.dashboard-wrapper h2 {
  font-size: 18px;
}

.dashboard-wrapper h4 {
  font-size: 16px;
}

.dashboard-wrapper .user-img {
  width: 120px;
  border-radius: 100px;
}

.dashboard-user-profile .user-img {
  width: 180px;
}

.dashboard-user-profile .user-profile-list {
  margin-top: 30px;
  padding-left: 30px;
}

.dashboard-user-profile .user-profile-list li {
  margin-bottom: 8px;
}

.dashboard-user-profile .user-profile-list span {
  font-weight: bold;
  margin-right: 5px;
  width: 100px;
  display: inline-block;
}

/*=================================================================
  Latest Posts
==================================================================*/
.blog {
  background: #F6F6F6;
}

.post {
  background: #fff;
  margin-bottom: 40px;
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
}

.post .post-media.post-thumb img {
  width: 100%;
  height: auto;
}

.post .post-media.post-media-audio iframe {
  width: 100%;
}

.post .post-title {
  font-size: 20px;
  margin-top: 10px;
  margin: 25px 0 0;
  padding: 0 20px 5px;
  font-weight: 300;
}

.post .post-title a {
  color: #000;
}

.post .post-title a:hover {
  color: #000;
}

.post .post-meta {
  font-size: 13px;
  margin-top: 5px;
  padding: 0 20px 5px;
}

.post .post-meta ul li {
  display: inline-block;
  color: #5f5b5b;
  margin-right: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 200;
}

.post .post-meta ul li a {
  color: #5f5b5b;
}

.post .post-meta ul li a:hover {
  color: #000;
}

.post .post-meta .post-author {
  color: #000;
}

.post .post-content {
  padding: 5px 20px;
}

.post .post-content p {
  color: #444;
  font-size: 14px;
  margin: 10px 0;
}

.post .post-content .btn-main, .post .post-content .btn-solid-border, .post .post-content .btn-transparent, .post .post-content .btn-small {
  padding: 10px 20px;
  margin: 15px 0;
  font-size: 10px;
}

.post-pagination {
  margin-top: 70px;
}

.post-pagination > li {
  margin: 0 2px;
  display: inline-block;
  font-size: 12px;
}

.post-pagination > li > a {
  color: #000;
}

.post-pagination > li > a:hover {
  color: #fff;
  background: #000;
  border: 1px solid #000;
}

.post-pagination > li.active > a {
  background: #000;
  border: 1px solid #000;
}

.post-pagination > li:first-child > a, .post-pagination > li:last-child > a {
  border-radius: 0;
}

/*=================================================================
  Single Blog Page
==================================================================*/
.post.post-single {
  border: none;
}

.post-sub-heading {
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
  letter-spacing: 2px;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 20px;
}

.post-social-share {
  margin-bottom: 50px;
}

.post-comments {
  margin: 30px 0;
}

.post-comments .media {
  margin-top: 15px;
}

.post-comments .media > .pull-left {
  padding-right: 20px;
}

.post-comments .comment-author {
  margin-top: 0;
  margin-bottom: 3px;
}

.post-comments .comment-author a {
  color: #000;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}

.post-comments p {
  margin-bottom: 30px;
  font-size: 14px;
}

.post-comments time {
  margin: 0 0 5px;
  display: inline-block;
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 200;
}

.post-comments .comment-button {
  color: #7e7e7e;
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
}

.post-comments .comment-button i {
  margin-right: 5px;
  display: inline-block;
}

.post-comments .comment-button:hover {
  color: #000;
}

.post-excerpt {
  padding: 0 20px;
  margin-bottom: 60px;
}

.post-excerpt h3 a {
  color: #000;
}

.post-excerpt blockquote {
  line-height: 22px;
  margin: 20px 0;
  font-size: 16px;
}

.post-excerpt p {
  font-size: 16px;
  color: #5e5e5e;
  margin: 0 0 30px;
  line-height: 30px;
}

.single-blog {
  background-color: #fff;
  margin-bottom: 50px;
  padding: 20px;
}

.blog-subtitle {
  font-size: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.next-prev {
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  margin: 20px 0;
  padding: 25px 0;
}

.next-prev a {
  color: #000;
}

.next-prev a:hover {
  color: #000;
}

.next-prev .prev-post i {
  margin-right: 10px;
}

.next-prev .next-post i {
  margin-left: 10px;
}

.social-profile ul li {
  margin: 0 10px 0 0;
  display: inline-block;
}

.social-profile ul li a {
  color: #4e595f;
  display: block;
  font-size: 16px;
}

.social-profile ul li a i:hover {
  color: #000;
}

.comments-section {
  margin-top: 35px;
}

.author-about {
  margin-top: 40px;
}

.post-author {
  margin-right: 20px;
}

.post-author > img {
  border: 1px solid #dedede;
  max-width: 120px;
  padding: 5px;
  width: 100%;
}

.comment-list ul {
  margin-top: 20px;
}

.comment-list ul li {
  margin-bottom: 20px;
}

.comment-wrap {
  border: 1px solid #dedede;
  border-radius: 1px;
  margin-left: 20px;
  padding: 10px;
  position: relative;
}

.comment-wrap .author-avatar {
  margin-right: 10px;
}

.comment-wrap .media .media-heading {
  font-size: 14px;
  margin-bottom: 8px;
}

.comment-wrap .media .media-heading a {
  color: #000;
  font-size: 13px;
}

.comment-wrap .media .comment-meta {
  font-size: 12px;
  color: #888;
}

.comment-wrap .media p {
  margin-top: 15px;
}

.comment-reply-form {
  margin-top: 80px;
}

.comment-reply-form input, .comment-reply-form textarea {
  height: 35px;
  border-radius: 0;
  box-shadow: none;
}

.comment-reply-form input:focus, .comment-reply-form textarea:focus {
  box-shadow: none;
  border: 1px solid #000;
}

.comment-reply-form textarea, .comment-reply-form .btn-main, .comment-reply-form .btn-solid-border, .comment-reply-form .btn-transparent, .comment-reply-form .btn-small {
  height: auto;
}

.widget {
  margin-bottom: 30px;
  padding-bottom: 35px;
}

.widget .widget-title {
  margin: 0;
  padding-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.widget.widget-subscription .form-group {
  margin-bottom: 8px;
}

.widget.widget-subscription .form-group input {
  font-size: 12px;
  font-weight: 200;
  height: 45px;
}

.widget.widget-subscription .btn-main, .widget.widget-subscription .btn-solid-border, .widget.widget-subscription .btn-transparent, .widget.widget-subscription .btn-small {
  width: 100%;
}

.widget.widget-latest-post .media .media-object {
  width: 150px;
  height: auto;
}

.widget.widget-latest-post .media .media-heading a {
  color: #000;
  font-size: 14px;
  font-weight: 300;
}

.widget.widget-latest-post .media p {
  font-size: 12px;
}

.widget.widget-category ul li {
  margin-bottom: 10px;
}

.widget.widget-category ul li a {
  color: #837f7e;
  font-weight: 200;
}

.widget.widget-category ul li a:before {
  padding-right: 10px;
  content: "\f3d1";
  font-family: "themefisher-font";
}

.widget.widget-category ul li a:hover {
  color: #000;
  padding-left: 5px;
}

.widget.widget-tag ul li {
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 5px;
}

.widget.widget-tag ul li a {
  color: #837f7e;
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #dedede;
  border-radius: 30px;
  font-size: 12px;
}

.widget.widget-tag ul li a:hover {
  color: #fff;
  background: #000;
  border: 1px solid #000;
}

.background {
  background-size: cover;
}

.bg-100 {
  height: 100vh;
}

.bg-1 {
  background-image: url(/images/backgrounds/bg-1.jpg);
  background-size: cover;
}

.bg-2 {
  background-size: cover;
}

.bg-2:before {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.bg-coming-soon {
  background-size: cover;
  height: 100vh;
}

.bg-brand-identity {
  background-repeat: no-repeat;
}

.coming-soon {
  color: #fff;
  padding: 150px 0;
}

.coming-soon .block h1 {
  font-size: 25px;
  line-height: 40px;
  font-weight: 400;
}

.coming-soon .block p {
  color: #fff;
  margin-top: 20px;
  font-size: 12px;
}

.coming-soon .block .count-down .syotimer-cell {
  width: 25%;
  display: inline-block;
}

.coming-soon .block .count-down .syotimer-cell .syotimer-cell__value {
  font-size: 80px;
  line-height: 80px;
  text-align: center;
  position: relative;
  font-weight: bold;
}

.coming-soon .block .count-down .syotimer-cell .syotimer-cell__unit {
  font-weight: normal;
}

@media (max-width: 768px) {
  .coming-soon .block .count-down ul li {
    font-size: 50px;
  }
}

@media (max-width: 480px) {
  .coming-soon .block .count-down ul li {
    font-size: 50px;
  }
}

@media (max-width: 400px) {
  .coming-soon .block .count-down ul li {
    font-size: 40px;
  }
}

.coming-soon .block .count-down ul li:before {
  content: ":";
  font-size: 20pt;
  opacity: 0.7;
  position: absolute;
  right: 0px;
  top: 0px;
}

.coming-soon .block .count-down ul li:last-child:before {
  content: "";
}

.coming-soon .block .count-down div:after {
  content: " " attr(data-interval-text);
  font-size: 20px;
  font-weight: normal;
  text-transform: capitalize;
  display: block;
}

.account .block {
  background-color: #fff;
  border: 1px solid #dedede;
  padding: 30px;
  margin: 100px 0;
}

.account .block .logo {
  display: inline-block;
}

.account .block a {
  color: #000;
}

.account .block h2 {
  font-weight: 400;
  font-size: 25px;
  text-transform: uppercase;
  margin-top: 40px;
}

.account .block form {
  margin-top: 40px;
}

@media (max-width: 400px) {
  .account .block form .btn-main, .account .block form .btn-solid-border, .account .block form .btn-transparent, .account .block form .btn-small {
    padding: 14px 19px;
  }
}

.account .block form p {
  margin-bottom: 20px;
}

.account .block form input[type=email], .account .block form input[type=password], .account .block form input[type=text] {
  border-radius: 0;
  box-shadow: none;
}

.shopping .widget-title {
  font-weight: 400;
  border-bottom: 1px solid #dedede;
  padding-bottom: 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
}

.checkout .block {
  padding-top: 15px;
  margin-bottom: 10px;
}

.checkout-form .form-group {
  position: relative;
  margin-bottom: 8px;
}

.checkout-form .form-group label {
  position: absolute;
  top: 18px;
  left: 15px;
  right: auto;
  bottom: auto;
  color: #888;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  opacity: 1 !important;
  width: 85px;
}

.checkout-form .form-group input {
  border-radius: 0;
  display: block;
  padding: 6px 10px 5px 100px;
  -moz-appearance: none;
  -webkit-appearance: none;
  height: 50px;
}

.checkout-form .checkout-country-code .form-group {
  float: left;
}

.checkout-form .checkout-country-code .form-group:first-child {
  width: calc(45% - 2px);
  margin-right: 4px;
}

.checkout-form .checkout-country-code .form-group:last-child {
  width: calc(55% - 2px);
}

.shopping.cart .product-list .table .cart-amount th {
  background: #f9f9f9;
  padding: 10px;
  text-transform: uppercase;
}

.shopping.cart .product-list .table > tbody > tr > td {
  vertical-align: middle;
}

.shopping.cart .product-list .product-info a {
  margin-left: 10px;
  color: #000;
  font-weight: 600;
}

.shopping.cart .product-list .product-remove {
  color: #c7254e;
}

.shopping.cart .account-details {
  margin-top: 30px;
}

.shopping.cart .account-details legend {
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}

.shopping.cart .account-details .btn-pay {
  margin: 20px 0;
}

.product-checkout-details .product-card > a {
  padding-right: 20px;
}

.product-checkout-details .product-card .price {
  margin-top: 15px;
}

.product-checkout-details .product-card .media-object {
  width: 80px;
}

.product-checkout-details .product-card h4 {
  font-weight: 400;
  font-size: 14px;
  color: #555;
}

.product-checkout-details .product-card .remove {
  font-size: 12px;
  cursor: pointer;
}

.product-checkout-details .discount-code {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  margin: 10;
  padding: 10px 0;
}

.product-checkout-details .discount-code p {
  margin: 0;
}

.product-checkout-details .discount-code p a {
  font-weight: 400;
  color: #555;
}

.product-checkout-details .summary-prices {
  border-style: solid;
  border-color: #dedede;
  border-width: 0px 0 1px 0;
  padding-bottom: 10px;
}

.product-checkout-details .summary-prices li {
  padding: 5px 0;
}

.product-checkout-details .summary-prices li span + span {
  float: right;
}

.product-checkout-details .summary-total {
  margin-top: 5px;
}

.product-checkout-details .summary-total > span {
  font-weight: 500;
  font-size: 18px;
}

.product-checkout-details .summary-total span + span {
  float: right;
}

.product-checkout-details .verified-icon {
  margin-top: 25px;
}

.product-checkout-details .verified-icon img {
  width: 100%;
}

.purchase-confirmation .purchase-confirmation-details {
  padding: 20px;
  border: 1px solid #dedede;
}

.purchase-confirmation .purchase-confirmation-details .table {
  margin: 0;
  color: #444;
}

.purchase-confirmation .purchase-confirmation-details .table b, .purchase-confirmation .purchase-confirmation-details .table strong {
  font-weight: 400;
}

.empty-cart .block i {
  font-size: 50px;
}

.success-msg .block i {
  font-size: 40px;
  background: #1bbb1b;
  color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  display: inline-block;
  line-height: 60px;
}

.page-404 {
  padding: 100px 0;
  text-align: center;
}

.page-404 h1 {
  font-size: 300px;
  font-weight: bold;
  line-height: 300px;
  margin-top: 30px;
}

@media (max-width: 480px) {
  .page-404 h1 {
    font-size: 130px;
    line-height: 150px;
  }
}

@media (max-width: 400px) {
  .page-404 h1 {
    font-size: 100px;
    line-height: 100px;
  }
}

@media (max-width: 768px) {
  .page-404 h1 {
    font-size: 150px;
    line-height: 200px;
  }
}

.page-404 h2 {
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 4px;
  font-weight: bold;
  margin-top: 30px;
}

.page-404 .copyright-text {
  margin-top: 50px;
  font-size: 12px;
}

.page-404 .btn-main, .page-404 .btn-solid-border, .page-404 .btn-transparent, .page-404 .btn-small {
  margin-top: 40px;
}

/*=================================================================
  Contact
  ==================================================================*/
.contact-us {
  padding: 100px 0;
}

.contact-form {
  margin-bottom: 40px;
}

.contact-form .form-control {
  background-color: transparent;
  border: 1px solid #dedede;
  box-shadow: none;
  height: 45px !important;
  color: #0c0c0c;
  height: 38px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  border-radius: 0;
}

.contact-form input:hover,
.contact-form textarea:hover,
.contact-form #contact-submit:hover {
  border-color: #000;
}

.contact-form #contact-submit {
  border: none;
  padding: 15px 0;
  width: 100%;
  margin: 0;
  background: #000;
  color: #fff;
  border-radius: 0;
}

.contact-form textarea.form-control {
  padding: 10px;
  height: 120px !important;
  outline: none;
}

.contact-details #map {
  width: 100%;
  height: 300px;
}

.contact-details .contact-short-info {
  margin-top: 20px;
}

.contact-details .contact-short-info li {
  margin-bottom: 6px;
  color: #555;
  font-weight: 300;
}

.contact-details .contact-short-info li i {
  margin-right: 10px;
}

.social-icon {
  margin-top: 20px;
}

.social-icon ul li {
  display: inline-block;
  margin-right: 10px;
}

@media (max-width: 400px) {
  .social-icon ul li {
    margin-bottom: 5px;
    margin-right: 5px;
  }
}

.social-icon ul li a {
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid #000;
  text-align: center;
}

.social-icon ul li a:hover {
  background: #000;
  color: #fff;
  border: 1px solid #000;
}

.social-icon ul li a:hover i {
  color: #fff;
}

.social-icon ul li a i {
  color: #000;
  display: inline-block;
  font-size: 20px;
  line-height: 50px;
  margin: 0;
}

.error {
  display: none;
  padding: 10px;
  color: #D8000C;
  border-radius: 4px;
  font-size: 13px;
  background-color: #FFBABA;
}

.success {
  background-color: #6cb670;
  border-radius: 4px;
  color: #fff;
  display: none;
  font-size: 13px;
  padding: 10px;
}

.footer {
  background: #F7F7F7;
  padding: 50px 0;
}

.footer .footer-menu {
  margin: 0;
  padding: 0;
}

.footer .footer-menu li {
  margin-bottom: 5px;
}

.footer .footer-menu li a {
  color: #000 !important;
  font-size: 15px;
}

.footer .copyright-text {
  margin-top: 20px;
  color: #fff;
}

.copyright-text a {
  color: #000;
}

.social-media li {
  display: inline-block;
  margin: 0 5px;
}

.social-media li a {
  padding: 8px 10px;
}

.social-media li a i {
  font-size: 20px;
  color: #555;
}



/*CUSTOM*/

.page_wrapper {
  padding-top: 25px !important;
  padding-bottom: 60px !important;
}

@media (max-width: 768px) {
  .page_wrapper {
    padding-top: 25px !important;
    padding-bottom: 30px !important;
  }
}

.btn-sm {
  padding: 12px 30px 13px 30px !important;
  font-size: 12px !important;
}

.btn-brand:hover {
  color: #744333 !important;
}

a {
  color: #000 !important;
}

.link {
  color: #000 !important;
  text-decoration: none;
}

.link:hover {
  cursor: pointer;
}

.cart small {
  font-size: 12px !important;
}

.product-description ul {
  padding-left: 12px !important;
  list-style: disc;
}

.product-description address {
  margin-bottom: 0 !important;
  padding: 0 !important;
}

select {
  border-radius: 0 !important;
  font-size: 12px !important;
  font-weight: 200;
  display: block;
  padding: 6px 10px 5px 100px !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  height: 50px;
}

/*custom checkboxes and radios*/

.form-check-input:checked {
  background-color: #000 !important;
  border-color: #000 !important;
}

.form-check-input:checked {
  color: #000 !important;
}

.accordion-body ul {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.accordion-body li {
  list-style: disc;
}

.swal2-actions .swal2-confirm,
.swal2-actions .swal2-cancel {
  border-radius: 0 !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #744333 !important;
  color: #ffffff !important;
  padding: 10px 20px 10px 20px !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border: 0 !important;
}

.paragraph p{
  width: 100%;
  text-align: center;
  font-size: 20px !important;
}

.form-group input, .btn-brand, .btn-brand:hover, textarea{
  border-radius: 0 !important;
}

.btn-brand{
  border-radius: 0 !important;
}
</style>